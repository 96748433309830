import React from 'react'

import Typist from 'react-typist';



import DangerousHTML from 'dangerous-html/react'
import { Helmet } from 'react-helmet'


import logo from './logo.png';

import './home.css'

function handleNavigation(event) {
  event.preventDefault();
  const sectionId = event.target.getAttribute("data-section-id");
  const section = document.getElementById(sectionId);
  if (section) {
    section.scrollIntoView({ behavior: "smooth" });
  }
}

function openUrlInNewTab(url) {
  const newTab = window.open(url, '_blank');
  newTab.focus();
}


function sendEmail() {
  window.location.href = 'mailto:nickgorbusiness@gmail.com';
}

const Home = (props) => {
  return (
    <div className="home-container">
      <Helmet>
        <title>FlowMinds</title>
        <meta property="og:title" content="FlowMinds" />
      </Helmet>
      <header data-thq="thq-navbar" className="home-navbar">
        <span className="home-logo">FLOWMINDS</span>
        <div
          data-thq="thq-navbar-nav"
          data-role="Nav"
          className="home-desktop-menu"
        >
          
	

	<nav
            data-thq="thq-navbar-nav-links"
            data-role="Nav"
            className="home-nav"
          >
       
        <button className="home-button button-clean button" data-section-id="home-about" onClick={handleNavigation}>About</button>
        
      <button className="home-button1 button-clean button" data-section-id="home-project" onClick={handleNavigation}>Process</button>
        
      <button className="home-button2 button-clean button" data-section-id="home-framework" onClick={handleNavigation}>Framework</button>
        
      <button className="home-button3 button-clean button" data-section-id="home-team" onClick={handleNavigation}>Team</button>
    
        </nav>

        </div>
        <div data-thq="thq-navbar-btn-group" className="home-btn-group">
          <div className="home-socials">
            <button className="social button" onClick={() => openUrlInNewTab('https://twitter.com/RelentlessNik')}>
              <img
                alt="image"
                src="/playground_assets/twitter.svg"
                className="home-image"
              />
            </button>
          </div>
          <button className="button" onClick={() => openUrlInNewTab('https://calendly.com/nickgorbusiness/30min')}>Get Free AI Audit</button>
        </div>
        <div data-thq="thq-burger-menu" className="home-burger-menu">
          <button className="button home-button5">
            <svg viewBox="0 0 1024 1024" className="home-icon">
              <path d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
            </svg>
          </button>
        </div>
       

      <div data-thq="thq-mobile-menu" className="home-mobile-menu">
          <div
            data-thq="thq-mobile-menu-nav"
            data-role="Nav"
            className="home-nav1"
          >
            <div className="home-container1">
              <span className="home-logo1">FLOWMINDS</span>
              <div data-thq="thq-close-menu" className="home-menu-close">
                <svg viewBox="0 0 1024 1024" className="home-icon02">
                  <path d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"></path>
                </svg>
              </div>
            </div>
         

      <nav
              data-thq="thq-mobile-menu-nav-links"
              data-role="Nav"
              className="home-nav2"
            >
	          
      <button className="home-text button-clean button" data-section-id="home-about" onClick={handleNavigation}>About</button>

      <button className="home-text01 button-clean button" data-section-id="home-project" onClick={handleNavigation}>Process</button>

      <button className="home-text02 button-clean button" data-section-id="home-framework" onClick={handleNavigation}>Framework</button>

      <button className="home-text03 button-clean button" data-section-id="home-team" onClick={handleNavigation}>Team</button>

            </nav>



            <div className="home-container2">
              <button className="home-login button">Login</button>
              <button className="button">Register</button>
            </div>
          </div>
          <div className="home-icon-group">
            <svg viewBox="0 0 950.8571428571428 1024" className="home-icon04">
              <path d="M925.714 233.143c-25.143 36.571-56.571 69.143-92.571 95.429 0.571 8 0.571 16 0.571 24 0 244-185.714 525.143-525.143 525.143-104.571 0-201.714-30.286-283.429-82.857 14.857 1.714 29.143 2.286 44.571 2.286 86.286 0 165.714-29.143 229.143-78.857-81.143-1.714-149.143-54.857-172.571-128 11.429 1.714 22.857 2.857 34.857 2.857 16.571 0 33.143-2.286 48.571-6.286-84.571-17.143-148-91.429-148-181.143v-2.286c24.571 13.714 53.143 22.286 83.429 23.429-49.714-33.143-82.286-89.714-82.286-153.714 0-34.286 9.143-65.714 25.143-93.143 90.857 112 227.429 185.143 380.571 193.143-2.857-13.714-4.571-28-4.571-42.286 0-101.714 82.286-184.571 184.571-184.571 53.143 0 101.143 22.286 134.857 58.286 41.714-8 81.714-23.429 117.143-44.571-13.714 42.857-42.857 78.857-81.143 101.714 37.143-4 73.143-14.286 106.286-28.571z"></path>
            </svg>
            <svg viewBox="0 0 877.7142857142857 1024" className="home-icon06">
              <path d="M585.143 512c0-80.571-65.714-146.286-146.286-146.286s-146.286 65.714-146.286 146.286 65.714 146.286 146.286 146.286 146.286-65.714 146.286-146.286zM664 512c0 124.571-100.571 225.143-225.143 225.143s-225.143-100.571-225.143-225.143 100.571-225.143 225.143-225.143 225.143 100.571 225.143 225.143zM725.714 277.714c0 29.143-23.429 52.571-52.571 52.571s-52.571-23.429-52.571-52.571 23.429-52.571 52.571-52.571 52.571 23.429 52.571 52.571zM438.857 152c-64 0-201.143-5.143-258.857 17.714-20 8-34.857 17.714-50.286 33.143s-25.143 30.286-33.143 50.286c-22.857 57.714-17.714 194.857-17.714 258.857s-5.143 201.143 17.714 258.857c8 20 17.714 34.857 33.143 50.286s30.286 25.143 50.286 33.143c57.714 22.857 194.857 17.714 258.857 17.714s201.143 5.143 258.857-17.714c20-8 34.857-17.714 50.286-33.143s25.143-30.286 33.143-50.286c22.857-57.714 17.714-194.857 17.714-258.857s5.143-201.143-17.714-258.857c-8-20-17.714-34.857-33.143-50.286s-30.286-25.143-50.286-33.143c-57.714-22.857-194.857-17.714-258.857-17.714zM877.714 512c0 60.571 0.571 120.571-2.857 181.143-3.429 70.286-19.429 132.571-70.857 184s-113.714 67.429-184 70.857c-60.571 3.429-120.571 2.857-181.143 2.857s-120.571 0.571-181.143-2.857c-70.286-3.429-132.571-19.429-184-70.857s-67.429-113.714-70.857-184c-3.429-60.571-2.857-120.571-2.857-181.143s-0.571-120.571 2.857-181.143c3.429-70.286 19.429-132.571 70.857-184s113.714-67.429 184-70.857c60.571-3.429 120.571-2.857 181.143-2.857s120.571-0.571 181.143 2.857c70.286 3.429 132.571 19.429 184 70.857s67.429 113.714 70.857 184c3.429 60.571 2.857 120.571 2.857 181.143z"></path>
            </svg>
            <svg viewBox="0 0 602.2582857142856 1024" className="home-icon08">
              <path d="M548 6.857v150.857h-89.714c-70.286 0-83.429 33.714-83.429 82.286v108h167.429l-22.286 169.143h-145.143v433.714h-174.857v-433.714h-145.714v-169.143h145.714v-124.571c0-144.571 88.571-223.429 217.714-223.429 61.714 0 114.857 4.571 130.286 6.857z"></path>
            </svg>
          </div>
        </div>
      </header>
      <section id = "home-hero-1" className="home-hero">
        <div className="home-heading">
	              <Typist className="home-header" cursor={{ show: false }}>
                Supercharge ⭐ Your Real Estate Business 🏠 with AI
            </Typist>
          <p className="home-caption">
	  Save 50% of your customer service costs with AI Automation

          </p>
        </div>
        <div className="home-buttons">
          <button className="button" onClick={() => openUrlInNewTab('https://calendly.com/nickgorbusiness/30min')}>Book a Free AI Demo</button>
        </div>
              <p className="home-paragraph">
        <div className="container">
          <div style={{ textAlign: 'center' }}>

	With 3+ years in buidling AI Virtual Assistants and CRM integrations, our team is set to boost your real estate game

      </div>

    </div>
              </p>
      </section>



      <section id = "home-about" className="home-cards">
        <div className="home-row">
          <div className="home-card">
            <div className="home-avatar">
              <img
                alt="image"
              src="/playground_assets/light-avatar.svg"
                className="home-avatar1"
              />
            </div>
            <div className="home-main">
              <div className="home-content01">
                <h2 className="home-header01">14+ Real Estate Firms Boosted</h2>
                <p className="home-description02">
	  Our team specializes in AI chatbot solutions and CRM integrations, enhancing the operations of numerous real estate firms. 
<br/>
<br/>
	  Your success is our mission.
                </p>
              </div>
            <button className="home-view4 button-link button" data-section-id="home-framework" onClick={handleNavigation}>
                <span className="home-text07">Learn more</span>
                <img
                  alt="image"
                  src="/playground_assets/arrow.svg"
                  className="home-image02"
                />
              </button>
            </div>
          </div>
          <div className="home-card01">
            <div className="home-avatar2">
              <img
                alt="image"
              src="/playground_assets/light-avatar.svg"
                className="home-avatar3"
              />
            </div>
            <div className="home-main1">
              <div className="home-content02">
                <h2 className="home-header02">
	  Transform Your Real Estate Biz
                </h2>
                <p className="home-description03">
	  AI isn't just a trend; it's an opportunity to outshine your competitors. 

	  Automate repetitive tasks and enhance client interactions with our AI solutions.
                </p>
              </div>

            <button className="home-view4 button-link button" data-section-id="home-framework" onClick={handleNavigation}>
                <span className="home-text08">Learn more</span>
                <img
                  alt="image"
                  src="/playground_assets/arrow-2.svg"
                  className="home-image03"
                />
              </button>
            </div>
          </div>
        </div>
        <div className="home-card02">
          <div className="home-avatar4">
            <img
              alt="image"
              src="/playground_assets/light-avatar.svg"
              className="home-avatar5"
            />
          </div>
          <div className="home-row1">
            <div className="home-main2">
              <div className="home-content03">
                <h2 className="home-header03">
	  Prepare Your Business for the AI Revolution in Real Estate

                </h2>
                <p className="home-description04">
	  The AI landscape is ever-evolving. Stay competitive by integrating AI chatbots and CRM tools into your real estate business.


                </p>
              </div>
            <button className="home-view4 button-link button" data-section-id="home-framework" onClick={handleNavigation}>
                <span className="home-text09">Learn more</span>
                <img
                  alt="image"
                  src="/playground_assets/arrow-2.svg"
                  className="home-image04"
                />
              </button>
            </div>
	   <div className="wcontainer">

            <img
              alt="image"
              src="/playground_assets/ai.jpg"
	  className="responsive-image"
	  style={{ width: '98%' }}
              className="home-image05"
            />
          </div>
          </div>
        </div>
      </section>



      <section id = "home-project" className="home-project">
        <div className="home-mining">
          <img
            alt="image"
            src="/playground_assets/group%202422.svg"
            className="home-image24"
          />
          <div className="home-content14">
            <span className="home-caption11">Upgrade Process</span>
            <div className="home-heading03">
              <h2 className="home-header08">How the process works</h2>
              <p className="home-header09">
                <span>
	  Our four-step process is tailored for the real estate sector, ensuring you get the best of AI chatbots and CRM integration. We start with a discovery phase, understanding your unique needs, followed by strategy development, implementation, and continuous support.

                </span>
                <br></br>
                <br></br>
                <span>
      With ongoing consultation and 24 * 7 support, we ensure that our solutions continue to deliver value and drive results for your business over time.
                </span>
                <br></br>
              </p>
            </div>
            <button className="home-view4 button-link button" data-section-id="home-framework" onClick={handleNavigation}>
              <span>Learn More</span>
              <img
                alt="image"
                src="/playground_assets/arrow.svg"
                className="home-image25"
              />
            </button>
          </div>
        </div>
      </section>

      <section id = "home-framework" className="home-roadmap">
        <div className="home-heading04">
          <h2 className="home-header10">AI Framework</h2>
          <p className="home-header11">
	  Empowering real estate businesses to achieve maximum growth.
          </p>
        </div>
        <div className="home-list">
          <div className="home-step">
            <span className="home-caption12">01</span>
            <div className="home-heading05">
              <h2 className="home-header12">Discovery and Audit</h2>
              <p className="home-header13">
	  We dive deep into understanding your real estate business, identifying areas where AI chatbots and Automation can make a difference.
              </p>
            </div>
            <button className="home-button6 button" onClick={() => openUrlInNewTab('https://calendly.com/nickgorbusiness/30min')}>Start Your Free Audit</button>
          </div>
          <div className="home-step1">
            <span className="home-caption13">02</span>
            <div className="home-heading06">
              <h2 className="home-header14">Strategy and Blueprint</h2>
              <p className="home-header15">
                <span>
	  From our findings, we draft a plan. This blueprint ensures AI tools mesh well with your operations. It's tailored to make your tasks simpler and more efficient.
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
              </p>
            <button className="home-button6 button" onClick={() => openUrlInNewTab('https://calendly.com/nickgorbusiness/30min')}>Start Your Free Audit</button>
            </div>
          </div>
          <div className="home-step2">
            <span className="home-caption14">03</span>
            <div className="home-heading07">
              <h2 className="home-header16">Implementation and Optimization</h2>
              <p className="home-header17">
                <span>
	  We bring the blueprint to life, integrating AI chatbots and automation tools, ensuring they deliver value consistently.
	  These tools are fine-tuned to your needs. Our goal is consistent value and seamless operations.

                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />

                </span>
                <br></br>
                <br></br>
            <button className="home-button6 button" onClick={() => openUrlInNewTab('https://calendly.com/nickgorbusiness/30min')}>Start Your Free Audit</button>
                <span>
                </span>
                <br></br>
              </p>
            </div>
          </div>
          <div className="home-step3">
            <span className="home-caption15">04</span>
            <div className="home-heading08">
              <h2 className="home-header18">Consultation and Support</h2>
              <p className="home-header19">
	  Our commitment doesn't end at implementation.
	  We're here 24/7 for questions, tweaks, or advice. Day or night, we're ready to assist, ensuring everything runs smoothly.



                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </p>
              <div className="home-benefits">
                <div className="home-item">
                  <img
                    alt="image"
                    src="/playground_assets/people.svg"
                    className="home-image26"
                  />
                  <p className="home-header20">Customized Real Estate Solutions</p>
                </div>
                <div className="home-item1">
                  <img
                    alt="image"
                    src="/playground_assets/paper.svg"
                    className="home-image27"
                  />
                  <p className="home-header21">Guidance in Adopting New Technologies</p>
                </div>
                <div className="home-item2">
                  <img
                    alt="image"
                    src="/playground_assets/checklist.svg"
                    className="home-image28"
                  />
                  <p className="home-header22">Continuous Support and Troubleshooting</p>
                </div>
            <button className="home-button6 button" onClick={() => openUrlInNewTab('https://calendly.com/nickgorbusiness/30min')}>Start Your Free Audit</button>
              </div>
            </div>
          </div>
        </div>
      </section>



      <section id = "hom-join-us-1" className="home-join-us">
        <div className="home-content15">
          <div className="home-main4">
            <div className="home-heading09">
              <h2 className="home-header23">
Supercharge My Real Estate Business
	  </h2>
              <p className="home-caption16">
One click is all it takes to transform your real estate business.
              </p>
            </div>
            <button className="home-view5 button" onClick={() => openUrlInNewTab('https://calendly.com/nickgorbusiness/30min')}>Upgrade Your Business with AI</button>
          <br/>
          </div>
        </div>
      </section>




      <section id = "home-faq-1" className="home-faq">
        <h2 className="home-header24">I still have a question?</h2>
        <div className="home-accordion">
          <div
            data-role="accordion-container"
            className="home-element accordion"
          >
            <div className="home-content16">
              <span className="home-header25">
	  Q. What do AI chatbots and CRM integrations bring to real estate?

              </span>
              <span
                data-role="accordion-content"
                className="home-description05"
              >
	  AI chatbots are virtual assistants designed to automate client interactions, answer queries, and streamline tasks. When integrated with CRM systems, they ensure seamless and automated management of client data and interactions, enhancing the overall efficiency of your real estate operations.
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
            </div>



            <div className="home-icon-container">
              <svg
                viewBox="0 0 1024 1024"
                data-role="accordion-icon-closed"
                className="home-icon10"
              >
                <path d="M213.333 554.667h256v256c0 23.552 19.115 42.667 42.667 42.667s42.667-19.115 42.667-42.667v-256h256c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-256v-256c0-23.552-19.115-42.667-42.667-42.667s-42.667 19.115-42.667 42.667v256h-256c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
              </svg>
              <svg
                viewBox="0 0 1024 1024"
                data-role="accordion-icon-open"
                className="home-icon12"
              >
                <path d="M213.333 554.667h597.333c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-597.333c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
              </svg>
            </div>
          </div>
          <div
            data-role="accordion-container"
            className="home-element1 accordion"
          >
            <div className="home-content17">
              <span className="home-header26">
	  Q. How can my real estate business benefit from this automation?

              </span>
              <span
                data-role="accordion-content"
                className="home-description06"
              >
	  By automating client interactions with AI chatbots and integrating them with CRM systems, you can respond faster, manage client data efficiently, and reduce manual tasks. This leads to improved client satisfaction, increased sales, and a more streamlined operation.


                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
            </div>
            <div className="home-icon-container1">
              <svg
                viewBox="0 0 1024 1024"
                data-role="accordion-icon-closed"
                className="home-icon14"
              >
                <path d="M213.333 554.667h256v256c0 23.552 19.115 42.667 42.667 42.667s42.667-19.115 42.667-42.667v-256h256c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-256v-256c0-23.552-19.115-42.667-42.667-42.667s-42.667 19.115-42.667 42.667v256h-256c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
              </svg>
              <svg
                viewBox="0 0 1024 1024"
                data-role="accordion-icon-open"
                className="home-icon16"
              >
                <path d="M213.333 554.667h597.333c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-597.333c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
              </svg>
            </div>
          </div>
          <div
            data-role="accordion-container"
            className="home-element2 accordion"
          >
            <div className="home-content18">
              <span className="home-header27">
	  Q. Is it costly to bring this level of automation to my real estate business?
              </span>
              <span
                data-role="accordion-content"
                className="home-description07"
              >
	  While there's an initial investment in AI chatbots and CRM integrations, the automation they bring often results in time savings, increased sales, and enhanced client relationships, providing a significant return on investment.
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
            </div>
            <div className="home-icon-container2">
              <svg
                viewBox="0 0 1024 1024"
                data-role="accordion-icon-closed"
                className="home-icon18"
              >
                <path d="M213.333 554.667h256v256c0 23.552 19.115 42.667 42.667 42.667s42.667-19.115 42.667-42.667v-256h256c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-256v-256c0-23.552-19.115-42.667-42.667-42.667s-42.667 19.115-42.667 42.667v256h-256c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
              </svg>
              <svg
                viewBox="0 0 1024 1024"
                data-role="accordion-icon-open"
                className="home-icon20"
              >
                <path d="M213.333 554.667h597.333c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-597.333c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
              </svg>
            </div>
          </div>
          <div
            data-role="accordion-container"
            className="home-element3 accordion"
          >
            <div className="home-content19">
              <span className="home-header28">
	  Q. How long does it typically take to implement these automated solutions?
              </span>
              <span
                data-role="accordion-content"
                className="home-description08"
              >
	  The time to integrate AI chatbots and CRM systems varies based on your existing infrastructure and the level of customization required. However, the goal is always to swiftly bring automation to your operations, typically within weeks to a few months.

                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
            </div>
            <div className="home-icon-container3">
              <svg
                viewBox="0 0 1024 1024"
                data-role="accordion-icon-closed"
                className="home-icon22"
              >
                <path d="M213.333 554.667h256v256c0 23.552 19.115 42.667 42.667 42.667s42.667-19.115 42.667-42.667v-256h256c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-256v-256c0-23.552-19.115-42.667-42.667-42.667s-42.667 19.115-42.667 42.667v256h-256c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
              </svg>
              <svg
                viewBox="0 0 1024 1024"
                data-role="accordion-icon-open"
                className="home-icon24"
              >
                <path d="M213.333 554.667h597.333c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-597.333c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
              </svg>
            </div>
          </div>
          <div
            data-role="accordion-container"
            className="home-element4 accordion"
          >
            <div className="home-content20">
              <span className="home-header29">
	  Q. What kind of support can I expect post-implementation?
              </span>
              <span
                data-role="accordion-content"
                className="home-description09"
              >
	  Our commitment to your business doesn't end post-integration. We provide ongoing support to ensure the automated tools, including AI chatbots and CRM, continue to drive efficiency and results for your real estate business.
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
            </div>
            <div className="home-icon-container4">
              <svg
                viewBox="0 0 1024 1024"
                data-role="accordion-icon-closed"
                className="home-icon26"
              >
                <path d="M213.333 554.667h256v256c0 23.552 19.115 42.667 42.667 42.667s42.667-19.115 42.667-42.667v-256h256c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-256v-256c0-23.552-19.115-42.667-42.667-42.667s-42.667 19.115-42.667 42.667v256h-256c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
              </svg>
              <svg
                viewBox="0 0 1024 1024"
                data-role="accordion-icon-open"
                className="home-icon28"
              >
                <path d="M213.333 554.667h597.333c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-597.333c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
              </svg>
            </div>
          </div>
        </div>
      </section>
      <section id = "home-team" className="home-get-yours">
        <div className="home-row2">
         
	  <div className="home-column">
            <div className="home-card11">
              <img
                alt="image"
                src="/playground_assets/niks.jpg"
                className="home-image30"
              />
            </div>
          </div>
         
	  <div className="home-column1">
            <div className="home-card12">
              <img
                alt="image"
                src="/playground_assets/alex.jpg"
                className="home-image31"
              />
            </div>
          </div>

        </div>

        <div className="home-column2">
          <div className="home-card13">
            <div className="home-content21">
              <h2 className="home-header30">I'm Nikolay, CEO of FlowMinds.</h2>
              <p className="home-description10">
      Our Core team include Me, Alex, and Jesus. All with strong Computer Science and Business background.

	  <br/> <br/>
	  Your sucess is our priority. Shoot me a DM if you are active on Twitter or check my content on Youtube.  
              </p>
	              <div className="home-socials1">
	  Twitter:
              <a
                href="https://twitter.com/RelentlessNik"
                target="_blank"
                rel="noreferrer noopener"
                className="home-twitter1 social button"
              >
                <img
                  alt="image"
                  src="/playground_assets/twitter.svg"
                  className="home-image32"
                />
              </a>

              <a href="https://www.youtube.com/@nickgorian"
	                  target="_blank"
	  >
              <img
                alt="image"
                src="/playground_assets/youtube.svg"
                className="home-image"
              />
	  </a>
            </div>
            <button className="home-button7 button" onClick={() => openUrlInNewTab('https://calendly.com/nickgorbusiness/30min')}>Book a Free 15 min consultancy</button>

            </div>
          </div>
        </div>
      </section>
      <footer className="home-footer">
        <div className="home-main5">
          <div className="home-branding">
            <div className="home-heading10">
              <h2 className="home-logo2" style={{fontWeight: 'bold' }}>FLOWMINDS</h2>
              <p className="home-caption17">
      Transforming businesses with AI. Expert engineers creating custom solutions.
              </p>
            </div>
            <div className="home-socials1">
              <a
                href="https://twitter.com/RelentlessNik"
                target="_blank"
                rel="noreferrer noopener"
                className="home-twitter1 social button"
              >
                <img
                  alt="image"
                  src="/playground_assets/twitter.svg"
                  className="home-image32"
                />
              </a>


            <button className="social button" onClick={() => openUrlInNewTab('https://www.tiktok.com/@niks.ai')}>
              <img
                alt="image"
                src="/playground_assets/tiktok.svg"
                className="home-image"
              />
            </button>


            </div>
          </div>
          <div className="home-links1">
            <div className="home-list1">
              <h3 className="home-heading11">Links</h3>
              <div className="home-items">
                <button className="home-link04 button-clean button" onClick={sendEmail}>
                  Contact Us
                </button>
                <button className="home-link04 button-clean button">
                  <a href="https://twitter.com/RelentlessNik"  target="_blank">Twitter</a>
                </button>
                <button className="home-link04 button-clean button">
                  <a href="https://www.instagram.com/nicks.ai"  target="_blank">Instagram</a>
                </button>
              </div>
            </div>
            <div className="home-list2">
              <h3 className="home-heading12">
	  Company
	  </h3>
              <div className="home-items1">
                <button className="home-link06 button-clean button">
	  <a href = "https://www.linkedin.com/company/flowminds-ai/"  target="_blank">LinkedIn</a>
                </button>
                <button className="home-link06 button-clean button">
	  <a href = "https://www.instagram.com/flowminds.ai/"  target="_blank">Instagram</a>
                </button>
              </div>
            </div>
          </div>
          <div className="home-socials2">
            <a
              href="https://twitter.com/RelentlessNik"
              target="_blank"
              rel="noreferrer noopener"
              className="home-twitter2 social button"
            >
              <img
                alt="image"
                src="/playground_assets/twitter.svg"
                className="home-image34"
              />
            </a>
            <a
              href="https://discord.com"
              target="_blank"
              rel="noreferrer noopener"
              className="home-discord2 social button"
            >
              <img
                alt="image"
                src="/playground_assets/discord.svg"
                className="home-image35"
              />
            </a>
          </div>
        </div>
        <span className="home-copyright">
          © 2023 FLOWMINDS. All Rights Reserved.
        </span>
      </footer>
      <div>
        <DangerousHTML
          html={`<script>
 /*
  Accordion - Code Embed
  */
  const accordionContainers = document.querySelectorAll('[data-role="accordion-container"]'); // All accordion containers
  const accordionContents = document.querySelectorAll('[data-role="accordion-content"]'); // All accordion content
  const accordionIconsClosed = document.querySelectorAll('[data-role="accordion-icon-closed"]'); // All accordion closed icons
  const accordionIconsOpen = document.querySelectorAll('[data-role="accordion-icon-open"]'); // All accordion open icons

  accordionContents.forEach((accordionContent) => {
      accordionContent.style.display = "none"; //Hides all accordion contents
  });

  accordionIconsClosed.forEach((icon) => {
    icon.style.display = "flex"
  })

  accordionIconsOpen.forEach((icon) => {
    icon.style.display = "none"
  })

  accordionContainers.forEach((accordionContainer, index) => {
      accordionContainer.addEventListener("click", () => {
          if (accordionContents[index].style.display === "flex") {
              // If the accordion is already open, close it
              accordionContents[index].style.display = "none";
              accordionIconsClosed[index].style.display = "flex";
              accordionIconsOpen[index].style.display = "none"
          } else {
              // If the accordion is closed, open it
              accordionContents.forEach((accordionContent) => {
                  accordionContent.style.display = "none"; //Hides all accordion contents
              });

              accordionIconsClosed.forEach((accordionIcon) => {
                  accordionIcon.style.display = "flex"; // Resets all icon transforms to 0deg (default)
              });

              accordionIconsOpen.forEach((accordionIcon) => {
                accordionIcon.style.display = "none";
              })
              
              accordionContents[index].style.display = "flex"; // Shows accordion content
              accordionIconsClosed[index].style.display = "none"; // Rotates accordion icon 180deg
              accordionIconsOpen[index].style.display = "flex";
          }
      });
  });
</script>
`}
        ></DangerousHTML>
      </div>
    </div>
  )
}

export default Home
